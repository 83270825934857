/* eslint-disable react/display-name */
import React from "react";
import { Modal, Table } from "antd";
import moment from "moment";

import Helpers from "../../services/helpers";
import { useModalContext } from "../contexts/modalcontext";

const columns = [
  {
    title: "Date",
    dataIndex: "event_date",
    render: (date) => {
      const parsedDate = moment(date);
      return parsedDate.utcOffset(-1 * parsedDate.utcOffset()).format("MM/DD/YYYY");
    },
    sorter: Helpers.sortByFieldName("event_date"),
    defaultSortOrder: "descend",
    width: 115,
  },
  {
    title: "Note",
    dataIndex: "content",
  },
];

const PlayerNotesTable = () => {
  const { playerNotes: data, togglePlayerNotesModal, setPlayerNotesData } = useModalContext();

  const handleCancel = () => {
    togglePlayerNotesModal();
    setPlayerNotesData();
  };

  return (
    <Modal
      visible
      title={<span style={{ fontWeight: "bold" }}>Player Milestones</span>}
      onCancel={handleCancel}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(row) => row.id}
        pagination={{
          pageSize: 10,
        }}
      />
    </Modal>
  );
};

export default PlayerNotesTable;
