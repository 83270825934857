import React from "react";
import { Typography } from "antd";

const PDF_URL_1 = "https://docs.google.com/forms/d/e/1FAIpQLSfLK-3flnqHoThnnJQIdcC29-P9fh-dtUQTrnvjW27TwFrLHA/viewform";
const PDF_URL_2 = "https://drive.google.com/file/d/1CytUyJ_nsCVuY6imHpAX5_Zl1p7QL1iO/view";
const LIVESTREAM_URL =
  "https://www.youtube.com/watch?v=C5Poy8_u9nU&feature=youtu.be&utm_source=Sports+Card+Investor+Friends&utm_campaign=d10b5471ef-EMAIL_CAMPAIGN_2020_08_04_07_20_COPY_01&utm_medium=email&utm_term=0_2f96dfaf30-d10b5471ef-348603064";
const HELP_MAILTO_URL = "mailto:help@sportscardinvestor.com";
const REPORT_BUG_FORM_URL = "https://forms.gle/K5ukfZsZgozN4brx6";
const MISSING_SALE_FORM_URL = "https://forms.gle/SkV6MyNd5ThbtSmz8";

export const PlayerInfoText = () => {
  return (
    <Typography.Text>
      Please use{" "}
      <a href={PDF_URL_1} target="_blank" rel="noopener noreferrer">
        THIS FORM
      </a>{" "}
      to suggest a card to add to Market Movers.
      <br />
      <br />
      <strong>**Please note that this does not guaranty the card can be added.**</strong>
      <br />
      <br />
      There are hundreds of thousands of cards we could potentially add to our Popular Cards database. Our goal is to
      prioritize the most relevant, most popular cards on the market.
      <br />
      <br />
      You can use the{" "}
      <a href="/info/recently-added-cards" target="_blank">
        Recently Added Cards
      </a>{" "}
      report to check and see the latest additions to our database.
      <hr />
      For more information on how we decide which cards get added to Popular Cards, please visit our{" "}
      <a href="/info/faq" target="_blank">
        FAQ's page
      </a>
      .
      <br />
      <br />
      If the card you are looking for is not yet in Popular Cards, you can use{" "}
      <a href="/livesearch" target="_blank">
        Chart Other Cards
      </a>{" "}
      to chart recent sales history.
    </Typography.Text>
  );
};

export const BillingInfoText = () => {
  return (
    <Typography.Text>
      You can manage your subscription or payment method anytime by visiting{" "}
      <a href="https://www.sportscardinvestor.com" target="_blank" rel="noopener noreferrer">
        www.sportscardinvestor.com
      </a>{" "}
      and clicking on your name in the upper right hand corner.
      <br />
      <br />
      Select Subscriptions to upgrade, downgrade or cancel.
      <br />
      Select Payments to manage your payment method.
      <br />
      <br />
      Please note that any adjustments to either of these should be done at least 1 day prior to the billing date for
      the upcoming month.
      <br />
      <br />
      If you have any other questions or concerns, please email us at{" "}
      <a href={HELP_MAILTO_URL} target="_blank" rel="noopener noreferrer">
        help@sportscardinvestor.com
      </a>
      .
    </Typography.Text>
  );
};

export const OtherInfoText = () => {
  return (
    <Typography.Text>
      For other questions or requests, please email{" "}
      <a href={HELP_MAILTO_URL} target="_blank" rel="noopener noreferrer">
        help@sportscardinvestor.com
      </a>
      .
    </Typography.Text>
  );
};

export const MissingSaleInfoText = () => {
  return (
    <Typography.Text>
      Data quality and integrity is a huge priority for us. As such, we strive to make our eBay queries precise and to
      monitor and remove extreme outliers. For more on why a particular sale might be missing from our Popular Card
      Chart results, please review this{" "}
      <a href={PDF_URL_2} target="_blank" rel="noopener noreferrer">
        PDF
      </a>{" "}
      or this{" "}
      <a href={LIVESTREAM_URL} target="_blank" rel="noopener noreferrer">
        Livestream Recording
      </a>
      .<br />
      <br />
      If after reviewing this document you are still seeing significant sales missing, please fill out{" "}
      <a href={MISSING_SALE_FORM_URL} target="_blank" rel="noopener noreferrer">
        this form
      </a>{" "}
      to report the required information for us to investigate.
    </Typography.Text>
  );
};

export const ReportBugInfoText = () => {
  return (
    <Typography.Text>
      We are sorry that you are running into issues with Market Movers!
      <br />
      <br />
      Please use{" "}
      <a href={REPORT_BUG_FORM_URL} target="_blank" rel="noopener noreferrer">
        this form
      </a>{" "}
      to report the details of the issue so that we can investigate with our development team.
    </Typography.Text>
  );
};

export const generateBugContentString = ({ bugScreen, bugDevice, bugFreq, bugDescription }) =>
  `<p>Screen: ${bugScreen}</p><p>Device: ${bugDevice}</p><p>Frequency: ${bugFreq}</p><p>Details: ${bugDescription}</p>`;

export const TYPES = [
  { label: "Suggest a Card to Add", value: "Player" },
  { label: "Report a Bug", value: "Bug" },
  { label: "Report a Missing Sale", value: "Missing Sale" },
  { label: "Billing & Membership Questions", value: "Billing" },
  { label: "Other", value: "Other" },
];
