import React, { useState } from "react";
import { Modal, Row, Form, Typography, Select } from "antd";
import {
  PlayerInfoText,
  MissingSaleInfoText,
  ReportBugInfoText,
  TYPES,
  BillingInfoText,
  OtherInfoText,
} from "./addTicketModal.assets";

const AddTicketModal = ({ visible = false, onCancel }) => {
  const [ticketType, setTicketType] = useState(undefined);

  const handleCancel = () => {
    setTicketType(undefined);
    onCancel();
  };

  const renderContent = () => {
    switch (ticketType) {
      case "Player":
        return <PlayerInfoText />;
      case "Billing":
        return <BillingInfoText />;
      case "Other":
        return <OtherInfoText />;
      case "Bug":
        return <ReportBugInfoText />;
      case "Missing Sale":
        return <MissingSaleInfoText />;
      default:
        return null;
    }
  };

  return (
    <Modal title="Submit Ticket" visible={visible} destroyOnClose onCancel={handleCancel} footer={null}>
      <div style={{ margin: "0 14px" }}>
        <Row gutter={24} type="flex" justify="start" style={{ marginBottom: ".5rem" }}>
          {!ticketType && (
            <Typography.Text style={{ marginBottom: 20 }}>
              To submit a support ticket, please select one of the options below and be as specific as possible in your
              request. Provide the data requested for each support ticket type. We will respond to you as soon as
              possible.
            </Typography.Text>
          )}

          <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ width: "100%" }}>
            <Form.Item label="Type" required>
              <Select
                value={ticketType}
                style={{ width: "100%" }}
                onSelect={(val) => setTicketType(val)}
                placeholder="Select an Option"
              >
                {TYPES.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {renderContent()}
          </Form>
        </Row>
      </div>
    </Modal>
  );
};

export default AddTicketModal;
