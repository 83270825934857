import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal, Typography, Row, Col, Button } from "antd";
import { UPGRADE_TIER_MODAL_OBJECTS } from "./assets/upgradeTierModal.assets";
import { MembershipTiers } from "utils/user";

const UpgradeTierModal = ({ visible, object, onCancel = () => {} }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const items = Object.keys(UPGRADE_TIER_MODAL_OBJECTS).map((key) => UPGRADE_TIER_MODAL_OBJECTS[key]);
    setItems(items);
    setSelected(0);
  }, []);

  useEffect(() => {
    if (!object) setSelected(0);
  }, [object]);

  const _nextClick = () => {
    setSelected(selected + 1 >= items.length ? 0 : selected + 1);
  };

  const _prevClick = () => {
    setSelected(selected - 1 < 0 ? items.length - 1 : selected - 1);
  };

  const iconStyle = {
    position: "absolute",
    top: -24,
    bottom: 0,
    margin: "auto",
    height: 24,
    cursor: "pointer",
  };

  const toShow = object || items[selected];

  const membershipTierNames = toShow
    ? (function () {
        let _membershipTierNames;
        switch (toShow.title) {
          case "Card Ratios":
            _membershipTierNames = [MembershipTiers.PREMIUM, MembershipTiers.ULTIMATE];
            break;
          default:
            _membershipTierNames = [MembershipTiers.ULTIMATE];
            break;
        }
        return _membershipTierNames;
      })()
    : [MembershipTiers.ULTIMATE];

  // TODO: remove _(next|prev)Click code/UI

  return (
    items.length && (
      <Modal style={{ maxWidth: "90%" }} width={1200} visible={visible} onCancel={onCancel} title={""} footer={null}>
        <div className="modal-upgrade-tier">
          {!object && (
            <>
              <div style={{ ...iconStyle, right: -12 }} onClick={() => _nextClick()}>
                <RightOutlined style={{ fontSize: 24 }} />
              </div>
              <div style={{ ...iconStyle, left: -12 }} onClick={() => _prevClick()}>
                <LeftOutlined style={{ fontSize: 24 }} />
              </div>
            </>
          )}
          <Row type="flex" justify="center">
            {toShow.images.map(({ title, description, src, alt }) => (
              <Col key={title} xs={24} sm={12} lg={8} style={{ padding: 8 }}>
                <Typography.Title style={{ marginBottom: 8 }} level={4}>
                  {title}
                </Typography.Title>
                <Typography.Paragraph style={{ marginBottom: 12 }}>{description}</Typography.Paragraph>
                <img src={src} alt={alt} style={{ width: "100%", boxShadow: "0 5px 10px rgba(0, 0, 0, 0.13)" }} />
              </Col>
            ))}
          </Row>
          <Row type="flex" justify="center">
            <div style={{ textAlign: "center", paddingTop: 20 }}>
              <Typography.Paragraph style={{ fontSize: 16 }}>
                <b>{toShow.title}</b> is only available to <b>Market Movers {membershipTierNames.join(" and ")}</b>{" "}
                users.
                <br />
                <br />
                Upgrade now and you will get unlimited access to all these features!
                <br />
                We'll credit the unused portion of your current membership towards your upgraded membership.
              </Typography.Paragraph>
              <br />

              <div>
                <div style={{ display: "inline-block" }}>
                  <Button
                    type="primary"
                    size="large"
                    href="https://www.sportscardinvestor.com/register/market-movers-pro-monthly/"
                    target="_blank"
                    style={{ fontWeight: "bold", borderRadius: 24, color: "white", margin: 8 }}
                  >
                    SIGN UP FOR MONTHLY
                  </Button>
                  <Typography.Paragraph style={{ fontSize: 16 }}>
                    Only $25/month more
                    <br />
                    ($49.99/month total)
                  </Typography.Paragraph>
                </div>
                <div style={{ display: "inline-block" }}>
                  <Button
                    type="primary"
                    size="large"
                    href="https://www.sportscardinvestor.com/register/market-movers-pro-annual/"
                    target="_blank"
                    style={{ fontWeight: "bold", borderRadius: 24, color: "white", margin: 8, whiteSpace: "nowrap" }}
                  >
                    SIGN UP FOR ANNUAL
                  </Button>
                  <Typography.Paragraph style={{ fontSize: 16 }}>
                    Only $250/year more
                    <br />
                    ($499.99/year total)
                  </Typography.Paragraph>
                </div>
              </div>
              <br />
              <Typography.Paragraph style={{ fontSize: 16 }}>
                For questions, email{" "}
                <a href="mailto:members@sportscardinvestor.com" target="_blank" rel="noreferrer">
                  members@sportscardinvestor.com
                </a>
              </Typography.Paragraph>
            </div>
          </Row>
        </div>
      </Modal>
    )
  );
};

export default UpgradeTierModal;
