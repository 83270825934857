import { useEffect } from "react";
import { useRouter } from "next/router";

import useGlobal from "../../store";
import { logAndHandleAPIError } from "../../utils/errors";
import Services from "../../services";

const FetchCollectionIds = () => {
  const [, globalActions] = useGlobal();
  const router = useRouter();

  useEffect(() => {
    async function fetchCollectionIds() {
      try {
        const [cardIds, sealedWaxIds] = await Promise.all([
          Services.getPersonalCollectionCardList(),
          Services.getPersonalCollectionSealedWaxList(),
        ]);
        globalActions.setCollectionCardIds(cardIds);
        globalActions.setCollectionSealedWaxIds(sealedWaxIds);
      } catch (error) {
        logAndHandleAPIError(error, { router });
      }
    }
    fetchCollectionIds();
  }, []);

  return null;
};

export { FetchCollectionIds };
