import { useUserContext } from "./contexts/usercontext";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Helpers from "../services/helpers";
import { MembershipTiers, isUserAtLeast } from "../utils/user";
import { FetchCollectionIds } from "./personalCollection/FetchCollectionIds";
import AddTicketModal from "./modals/AddTicketModal";
import UpgradeTierModal from "./modals/UpgradeTierModal";
import PlayerNoteModal from "./modals/PlayerNoteModal";
import { useModalContext } from "./contexts/modalcontext";
import { Button } from "antd";

const AuthenticatedRoute = ({ children, requiresAdmin, requiresProTier, requiresPremiumTier }) => {
  const router = useRouter();
  const { isLoggedIn, user, isTokenExpired } = useUserContext();
  const {
    isUpgradeTierModalVisible,
    setIsUpgradeTierModalVisible,
    upgradeTierModalObject,
    resetUpgradeTierModal,
    isPlayerNotesModalVisible,
  } = useModalContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleSupportModal = () => {
    setIsModalVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!user || isTokenExpired()) {
      return Helpers.expiredRedirect();
    } else if (requiresAdmin && !user.is_admin) {
      router.push("/leaderboard");
    } else if (requiresProTier && !user.is_admin && !user.is_pro) {
      setIsUpgradeTierModalVisible(true);
      router.push("/dashboard");
    } else if (requiresPremiumTier && !user.is_admin && !isUserAtLeast(user, MembershipTiers.PREMIUM)) {
      setIsUpgradeTierModalVisible(true);
      router.push("/dashboard");
    }
  }, [user]);

  return isLoggedIn && !isTokenExpired() ? (
    <>
      <AddTicketModal visible={isModalVisible} onCancel={toggleSupportModal} />
      <FetchCollectionIds />
      <UpgradeTierModal
        visible={isUpgradeTierModalVisible}
        onCancel={resetUpgradeTierModal}
        object={upgradeTierModalObject}
      />
      {isPlayerNotesModalVisible && <PlayerNoteModal />}
      {children}
      <Button
        id="help-btn"
        type="primary"
        shape="round"
        style={{ position: "fixed", bottom: 20, right: 40, zIndex: 1 }}
        onClick={toggleSupportModal}
      >
        Support
      </Button>
    </>
  ) : null;
};

export default AuthenticatedRoute;
