import Toaster from "services/toaster";
import Helpers from "services/helpers";

// TODO: look into integrating this into Sentry

export function logAndPresentFetchError(error) {
  logAndPresentError(error, {
    messageToUser: "Failed to fetch results",
  });
}

export function logAndPresentConsoleError(error) {
  logAndPresentError(error, {
    messageToUser: "Something went wrong. Check the console log for more information.",
  });
}

export function logAndPresentError(error, { messageToUser = error.message } = {}) {
  console.error(error);
  Toaster.ErrorNotification(messageToUser);
}

export function logAndHandleAPIError(error, { router }) {
  console.error(error);
  Helpers.handleApiError(error, router);
}
